<template>
  <div class="roleManage">
    <ds-header title="角色管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="角色名称：">
        <a-input v-model="searchForm.roleName" placeholder="请输入角色名称" style="width: 200px" />
      </a-form-item>
      <a-form-item label="角色状态：">
        <a-select
          v-model="searchForm.status"
          style="width: 200px"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in statusOptions" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属租户：" v-if="isPlatformAccount">
        <a-select
          v-model="searchForm.tenantId"
          style="width: 200px"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in options" :key="option.tenantId" :value="option.tenantId">
            {{ option.tenantName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.roleManage.add)">
      <ds-button :type="$DSBT.DS_ADD" title="新建角色" @click="addRole" />
    </div>
    <div style="padding-top: 15px">
      <ds-table
        :rowKey="(record, index) => record.roleId"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
        bordered
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule
            :actions="getActions(text, record)"
            @action="action"
            :data="text"
            :status="text.status === '1' ? false : true"
          ></ActionModule>
        </span>
      </ds-table>
      <!-- <a-table
        :rowKey="(record, index) => record.roleId"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
        bordered
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule :actions="actions" @action="action" :data="text" :status="text.status === '1' ? false : true"></ActionModule>
        </span>
      </a-table> -->
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import * as api from "@/api/authManage/roleManage";
import { getLesseeList } from "@/api/authManage/lesseeManage";
import { REQUEST_CODE } from "@/constant/status";
import { mapState } from "vuex";
import moment from "moment";
import { isPlatformAccount } from "@/config";
import PagingConfig from "@/utils/pagingConfig";
import ActionConfig from "@/utils/actionConfig";
import ActionModule from "@/components/actionModule";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import SearchResetModule from "@/components/searchResetModule";
import { ShowTypeEnum } from './help'

export default {
  name: "RoleManage",
  components: {
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      //判断是否是平台账号
      isPlatformAccount: isPlatformAccount,
      searchForm: {
        //角色名称
        roleName: "",
        //角色状态
        status: undefined,
        //所属租户
        tenantId: undefined,
      },
      //角色状态选项
      statusOptions: [
        {
          id: "1",
          value: "启用",
        },
        {
          id: "0",
          value: "禁用",
        },
      ],
      //租户选项
      options: [],
      //展示数据
      table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: "角色名称",
            dataIndex: "roleName",
            key: "roleName",
            // width: "150px",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            // width: "100px",
            customRender: (text) => {
              return text !== "1" ? "禁用" : "启用";
            },
          },
          {
            title: "所属租户",
            dataIndex: "tenantName",
            key: "tenantName",
            width: "200px",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            // width: "200px",
            customRender: (text) => {
              return moment(text).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            // width: "200px",
          },
          {
            title: "操作",
            key: "action",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: PagingConfig.defaultPage,
          pageSize: PagingConfig.defaultPageSize,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //操作集合
      actions: [
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.authManage.roleManage.delete
        },
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: ActionConfig.edit.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.authManage.roleManage.edit
        },
        {
          type: ActionConfig.disabled.ACTION_TYPE,
          permissions: this.$buttonPermissionsConfig.authManage.roleManage.enable
        },
      ],
    };
  },
  methods: {
    /**
     * 动态生成操作项
     */
    getActions(text) {
      if(text && text.updateStatus) {
        return this.actions
      } else {
        return []
      }
    },

    //查询
    search() {
      this.refreshData();
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        roleName: "",
        status: undefined,
        tenantId: undefined,
      };
      this.refreshData();
    },
    //添加账号
    addRole() {
      this.$router.push({
        path: "/authManage/roleManage/addRole",
        query: {
          showType: ShowTypeEnum.ADD,
        },
      });
    },
    //编辑
    edit(text) {
      this.$router.push({
        path: "/authManage/roleManage/addRole",
        query: {
          showType: ShowTypeEnum.EDIT,
          roleId: text.roleId,
        },
      });
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: "是否确认删除该角色?",
        content: "删除后所有赋权该角色的账号将失去该角色权限",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteRole(text.roleId);
    },
    //禁用
    async forbidden(text) {
      const title = text.status === "1" ? "禁用" : "启用";
      const confirmData = {
        title: `是否确认${title}该角色?`,
        content: text.status === "1" ? `${title}后该角色下对应权限将无法使用` : `${title}后该角色下对应权限将开放使用`,
        type: text.status === "1" ? ConfirmConfig.CONFIRM_TYPE_WARNING : ConfirmConfig.CONFIRM_TYPE_SUCCESS,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.roleDisable(text.roleId, text.status === "1" ? "0" : "1");
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.edit(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      } else if (actionType === ActionConfig.disabled.ACTION_TYPE) {
        this.forbidden(data);
      }
    },
    //分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },

    /**网络请求 */
    //获取角色列表
    async getRoleList() {
      try {
        const result = await api.getRoleList(
          this.getListDataTidy(),
          this.table.pagination.current,
          this.table.pagination.pageSize
        );

        if (result.code === REQUEST_CODE) {
          this.table.dataInfo = result.data.roleList;
          this.table.pagination.total = result.data.count;
        }
      } catch (error) {
        this.$message.error("获取角色列表失败");
      }
    },
    //角色启禁用
    async roleDisable(id, status) {
      try {
        const result = await api.roleDisable(id, status);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);
          this.refreshData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error(status === "0" ? "禁用失败" : "解除禁用失败");
      }
    },
    //删除角色
    async deleteRole(id) {
      try {
        const result = await api.deleteRole(id);
        if (result.code === REQUEST_CODE) {
          this.$message.success(result.msg);
          this.refreshData();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error("删除失败");
      }
    },
    //获取租户列表
    async getLessee() {
      try {
        const searchForm = {
          userName: "",
          tenantName: "",
        };
        const result = await getLesseeList(searchForm, 1, 9999);
        this.options = result.data.list;
      } catch (error) {}
    },

    //刷新数据
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getRoleList();
    },

    /**权限处理 */
    //判断当前用户是否是平台级管理员 展示数据
    judgeCurrentUserAuth() {
      if (this.isPlatformAccount !== "1") {
        this.table.columns = [
          {
            title: "角色名称",
            dataIndex: "roleName",
            key: "roleName",
            // width: "150px",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            // width: "100px",
            customRender: (text) => {
              return text !== "1" ? "禁用" : "启用";
            },
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            // width: "200px",
            customRender: (text) => {
              return moment(text).format("YYYY-MM-DD HH:mm:ss");
            },
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            // width: "200px",
          }
        ];
      }
    },
    /**数据处理 */
    //获取角色列表数据处理
    getListDataTidy() {
      return {
        ...this.searchForm,
        status: this.searchForm.status ? this.searchForm.status : "",
        tenantId: this.searchForm.tenantId ? this.searchForm.tenantId : "",
      };
    },
  },
  created() {
    this.refreshData();
    this.judgeCurrentUserAuth();
    if (this.isPlatformAccount) {
      this.getLessee();
    }
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction());
  },
};
</script>

<style lang="scss" scoped>
</style>
